"use client";

import React from "react";

export const StarRating = ({ rating }) => {
  // Create an array of 5 elements, representing the 5 stars
  const totalStars = 5;
  let stars = [];
  for (let i = 1; i <= totalStars; i++) {
    // Check for full, half or empty stars
    if (i <= rating) {
      // Full Star
      stars.push(
        <span key={i} className="text-yellow-400">
          ★
        </span>
      );
    } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
      // Half Star
      stars.push(
        <span key={i} className="text-yellow-400">
          ☆
        </span>
      );
    } else {
      // Empty Star
      stars.push(
        <span key={i} className="text-gray-300">
          ★
        </span>
      );
    }
  }

  return <div>{stars}</div>;
};
