import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/vercel/path0/node_modules/@nextui-org/button/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Card"] */ "/vercel/path0/node_modules/@nextui-org/card/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Skeleton"] */ "/vercel/path0/node_modules/@nextui-org/skeleton/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewCard"] */ "/vercel/path0/src/components/home/ReviewCard.jsx");
