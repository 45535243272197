"use client";

import React, { useState } from 'react';
import Image from 'next/image';
import { StarRating } from './StarRating';

export const ReviewCard = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const reviewText = review.text;
  const shouldTruncate = reviewText.length > 130;
  const displayedText =
    isExpanded || !shouldTruncate
      ? reviewText
      : `${reviewText.substring(0, 130)}...`;

  return (
    <div
      className={`p-6 rounded-xl shadow-lg bg-E6E6E6 mb-2 ${
        isExpanded ? 'h-auto' : 'h-56'
      } max-w-md`}
    >
      {' '}
      {/* Adjusted height here */}
      <div className="flex items-center ml-2">
        <div>
          <Image
            className="rounded-full w-12 h-12" // Ensure this matches the desired size
            src={review.profile_photo_url || '/images/default-image.png'}
            alt={`${review.author_name}'s avatar`}
            width={48} // This sets the width of the image
            height={48} // This sets the height of the image, keep it equal to width for a circle
          />
        </div>
        <div className="ml-2">
          <p className="font-bold text-black">{review.author_name}</p>
          <p className="text-sm  text-gray-800">
            {new Date(review.time * 1000).toLocaleDateString()}
          </p>
        </div>
      </div>
      <div className="mt-4 ml-2">
        {/* Add stars here based on review.rating */}
        <StarRating rating={review.rating} />
        <p className="mt-2 text-gray-800">{displayedText}</p>
        {shouldTruncate && (
          <button
            onClick={toggleExpand}
            className=" text-1B51CA font-bold hover:text-blue-800 text-sm"
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        )}
      </div>
    </div>
  );
};
